import { BEFORE_GSTATS, SET_GPOINTS } from '../../redux/types'
import { emptyError } from '../../redux/shared/error/error.action'
import axios from 'axios'
import { ENV } from '../../config/config'
const { gamification } = ENV

export const beforeStats = () => {
    return {
        type: BEFORE_GSTATS
    }
}

// set gamification points
export const setPoints = () => dispatch => {
    dispatch(emptyError());
    axios?.[gamification.ponits.method](gamification.ponits.url).then((res) => {
        if (res) {
            dispatch({
                type: SET_GPOINTS,
                payload: res.data
            })
        }
    })
}

// create stats
export const createStats = (payload = {}) => dispatch => {
    dispatch(emptyError());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    axios?.[gamification.stats.method](gamification.stats.url, payload, config).then((res) => {
        if (res) {
            dispatch({
                type: BEFORE_GSTATS
            })
        }
    }).catch((err) => console.log('ERR received while making Gamification Stats: ', err))
}