// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// HOME
export const HOME_PAGE_DATA = 'HOME_PAGE_DATA';
export const BEFORE_HOME = 'BEFORE_HOME';

// AUCTIONS
export const BEFORE_AUCTION = 'BEFORE_AUCTION';
export const GET_AUCTIONS = 'GET_AUCTIONS';
export const REMOVE_FAVOURITE_AUCTION = 'REMOVE_FAVOURITE_AUCTION'
export const ADD_FAVOURITE_AUCTION = 'ADD_FAVOURITE_AUCTION'

// NFTS
export const BEFORE_NFT = 'BEFORE_NFT';
export const GET_NFTS = 'GET_NFTS';
export const GET_NFT = 'GET_NFT';
export const UPSERT_NFT = 'UPSERT_NFT';
export const SEARCH_NFT = 'SEARCH_NFT';
export const BEFORE_METADATA = 'BEFORE_METADATA';
export const REFRESH_METADATA = 'REFRESH_METADATA';
export const CANCEL_LISTING = 'CANCEL_LISTING';
export const BEFORE_LISTING = 'BEFORE_LISTING';
export const TRANSFER_NFT = 'TRANSFER_NFT';
export const BEFORE_TRANSFER_NFT = 'BEFORE_TRANSFER_NFT';
export const BUY_NFT = 'BUY_NFT';
export const BEFORE_BUY_NFT = 'BEFORE_BUY_NFT';
export const GET_HOMEPAGE_NFTS = 'GET_HOMEPAGE_NFTS';
export const BEFORE_HOMEPAGE_NFTS = 'BEFORE_HOMEPAGE_NFTS';
export const GET_EARNING_SECTION_NFTS = 'GET_EARNING_SECTION_NFTS';
export const BEFORE_EARNING_SECTION_NFTS = 'BEFORE_EARNING_SECTION_NFTS';
export const GET_STAKING_PERCENT = 'GET_STAKING_PERCENT';
export const BEFORE_STAKING_PERCENT = 'BEFORE_STAKING_PERCENT';

// NFT REPORTINGS
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORT = 'GET_REPORT';
export const BEFORE_REPORT = 'BEFORE_REPORT';
export const ADD_REPORT = 'ADD_REPORT';
export const ADD_REPORT_RESPONSE = 'ADD_REPORT_RESPONSE';
export const GET_REPORT_MESSAGES = 'GET_REPORT_MESSAGES';
export const GET_REPORTED_USERS = 'GET_REPORTED_USERS';
export const BEFORE_NFT_REPORT = 'BEFORE_NFT_REPORT';

// WALLET
export const BEFORE_WALLET = 'BEFORE_WALLET';
export const SET_WALLET = 'SET_WALLET';
export const GET_WALLET = 'GET_WALLET';
export const SET_WALLET_ERROR = 'SET_WALLET_ERROR';
export const REDIRECT_TO_WALLET = 'REDIRECT_TO_WALLET';

// USERS
export const BEFORE_USER = 'BEFORE_USER';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const SET_CREATORS = 'SET_CREATORS';
export const TOP_SELLERS = 'TOP_SELLERS';
export const SET_INDIVIDUAL_USER = 'SET_INDIVIDUAL_USER';
export const SET_BANNER = 'SET_BANNER';
export const USER_TRADE = 'USER_TRADE';

// CATEGORIES
export const BEFORE_CATEGORY = 'BEFORE_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';

// COLLECTION
export const BEFORE_COLLECTION = 'BEFORE_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const UPSERT_COLLECTION = 'UPSERT_COLLECTION';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const SET_LANDING_COLLECTIONS = 'SET_LANDING_COLLECTIONS';
export const GET_NOTABLE_DROPS = 'GET_NOTABLE_DROPS';
export const BEFORE_NOTABLE_DROPS = 'BEFORE_NOTABLE_DROPS';

// FOOTER
export const BEFORE_FOOTER = 'BEFORE_FOOTER';
export const GET_FOOTER = 'GET_FOOTER';

// FAQ
export const BEFORE_FAQ = 'BEFORE_FAQ';
export const GET_FAQS = 'GET_FAQS'

// OFFER
export const BEFORE_OFFER = 'BEFORE_OFFER';
export const GET_OFFERS = 'GET_OFFERS';
export const DELETE_OFFER = 'DELETE_OFFER';
export const CREATE_OFFER = 'CREATE_OFFER';
export const ACCEPT_OFFER = 'ACCEPT_OFFER';

// BID
export const BEFORE_BID = 'BEFORE_BID';
export const GET_BIDS = 'GET_BIDS';
export const DELETE_BID = 'DELETE_BID';
export const CREATE_BID = 'CREATE_BID';
export const ACCEPT_BID = 'ACCEPT_BID';

// NAVBAR
export const BEFORE_NAVBAR = 'BEFORE_BAR';
export const GET_NAVBAR = 'GET_NAVBAR';

// EXPLORE
export const BEFORE_EXPLORE = 'BEFORE_EXPLORE';
export const GET_EXPLORE = 'GET_EXPLORE';
export const SET_EXPLORE_ID = 'SET_EXPLORE_ID';
export const SET_ALL = 'SET_ALL';
export const BEFORE_ALL = 'BEFORE_ALL';
export const BEFORE_SET_ID = 'BEFORE_SET_ID'

// CONTACT
export const BEFORE_CONTACT = 'BEFORE_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';

// SETTINGS
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS';
export const GET_SETTINGS = 'GET_SETTINGS';

// DISCOVER
export const BEFORE_DISCOVER = 'BEFORE_DISCOVER';
export const GET_DISCOVER_NAV = 'GET_DISCOVER_NAV';

// ACTIVITY
export const BEFORE_ACTIVITY = 'BEFORE_ACTIVITY';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';

// RANKINGS
export const BEFORE_RANKING = 'BEFORE_RANKING';
export const GET_RANKINGS = 'GET_RANKINGS';
export const GET_TRENDING = 'GET_TRENDING';
export const BEFORE_TRENDING = 'BEFORE_TRENDING';
export const GET_TOP_COLLECTIONS = 'GET_TOP_COLLECTIONS';
export const BEFORE_TOP_COLLECTIONS = 'BEFORE_TOP_COLLECTIONS';

// EARNINGS
export const BEFORE_EARNING = 'BEFORE_EARNING';
export const GET_EARNINGS = 'GET_EARNINGS';

// APP
export const BEFORE_APP = 'BEFORE_APP';
export const SET_RATES = 'SET_RATES';

// BANNER
export const BEFORE_BANNER = 'BEFORE_BANNER';
export const GET_BANNER = 'GET_BANNER';

// INTEGRATION
export const BEFORE_INTEGRATION = 'BEFORE_INTEGRATION';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';

// HISTORY
export const GET_HISTORY = 'GET_HISTORY';
export const BEFORE_HISTORY = 'BEFORE_HISTORY';

// ATTRIBUTES
export const BEFORE_ATTRIBUTES = 'BEFORE_ATTRIBUTES';
export const GET_ATTRIBUTES = 'GET_ATTRIBUTES';

// CMS
export const GET_CONTENT_PAGES = 'GET_CONTENT_PAGES'
export const GET_CONTENT_PAGE = 'GET_CONTENT_PAGE'
export const BEFORE_CONTENT = 'BEFORE_CONTENT'

// FAVOURITES
export const GET_USER_FAVOURITES = 'GET_USER_FAVOURITES'
export const ADD_USER_FAVOURITE = 'ADD_USER_FAVOURITE'
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE'
export const BEFORE_FAVOURITE = 'BEFORE_FAVOURITE'

// STAY IN LOOP
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION'
export const BEFORE_SUBSCRIPTION = 'BEFORE_SUBSCRIPTION'

// GAMIFICATION
export const BEFORE_GSTATS = 'BEFORE_GSTATS'
export const SET_GPOINTS = 'SET_GPOINTS'
export const SET_CONTESTS = 'SET_CONTESTS'
export const BEFORE_CONTESTS = 'BEFORE_CONTESTS'
export const VERIFY_DISCOUNT = 'VERIFY_DISCOUNT'
export const BEFORE_VERIFY_DISCOUNT = 'BEFORE_VERIFY_DISCOUNT'
export const GIFT_CARDS = 'GIFT_CARDS'
export const BEFORE_GIFT_CARDS = 'BEFORE_GIFT_CARDS'

// BULK UPLOAD NFTS
export const BULK_UPLOAD_NFTS = 'BULK_UPLOAD_NFTS'
export const BEFORE_BULK_UPLOAD = 'BEFORE_BULK_UPLOAD'
export const BULK_UPLOAD_NFTS_LOGS = 'BULK_UPLOAD_NFTS_LOGS'

// FEEDBACK 
export const VIEW_FEEDBACKS = 'VIEW_FEEDBACKS'
export const ADD_FEEDBACK = 'ADD_FEEDBACK'
export const GET_FEEDBACK = 'GET_FEEDBACK'
export const BEFORE_FEEDBACKS = 'BEFORE_FEEDBACKS'

// FUNDRAISING
export const ADD_FUNDRAISER_NFTS = 'ADD_FUNDRAISER_NFTS'
export const BEFORE_MINT_NFTS = 'BEFORE_MINT_NFTS'
export const GET_FUNDRAISER_NFTS_STATS = 'GET_FUNDRAISER_NFTS_STATS'
export const BEFORE_FUNDRAISER_NFTS_STATS = 'BEFORE_FUNDRAISER_NFTS_STATS'