import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { Images } from '../../assets/asset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap';
import { beforeUser } from '../user/user.action';
import './header.css';
import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyntistHeader = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dropCheck, setDropCheck] = useState(true)

    const logoutHandler = () => {
        localStorage.clear()
        dispatch(beforeUser())
        setDropCheck(false)
        navigate('/')
    }

    const formatAddress = (address) => {
        return address ? address.substr(0, 6) + '...' + address.substr(-4) : null;
    }

    return (
        <>
            <header id="myntist-header" className="header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <strong className="logo d-inline-block align-top">
                        <Link to="/" className="d-inline-block align-top">
                            <LazyLoadImage className="img-fluid" /* effect = 'blur' */ src={Images.myntistLogo} alt="Site Logo" />
                        </Link>
                    </strong>
                </div>
                <div className="d-flex justify-content-end">
                    <Navbar expand="lg" className="pos-stat justify-content-end p-0 myntist-navbar" >
                        {(localStorage.getItem('encuse') && localStorage.getItem('encuse') !== 'null' && localStorage.getItem('encuse') !== null) && dropCheck
                            ?
                            <span className={`nav-item ${pathname?.toLowerCase() === '/login' ? 'active' : ''} button-span`}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="btn ml-lg-auto myntist-login-button d-flex align-items-center">
                                        <FontAwesomeIcon icon={faWallet} className="wallet-icon me-2" /><span className="wallet-address">{formatAddress(localStorage.getItem('connectedAddress'))}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item id="wallet-item-color" className={pathname?.toLowerCase() === '/profile' ? 'active-status' : ''} onClick={() => { navigate('/profile') }}>My Profile</Dropdown.Item>
                                        <Dropdown.Item id="wallet-item-color" onClick={logoutHandler}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                            :
                            <span className="button-span">
                                <Link className="myntist-login-button" to="/login">
                                    <span className="outer">
                                        <span className="inner">Login</span>
                                    </span>
                                </Link>
                                <Link className="myntist-login-button-mobile" to="/login" tabIndex="0">
                                    <span className="outer">
                                        <span className="inner"><FontAwesomeIcon icon={faRightToBracket} className="" /></span>
                                    </span>
                                </Link>
                            </span>
                        }
                    </Navbar>
                </div>
            </header>
        </>
    )
}

export default MyntistHeader