import { BEFORE_APP, SET_RATES } from './redux/types';

const initialState = {
    rate: null, // BNB to USD rate
    rateAuth: false,
    wbnbRate: null,
    wbnbRateAuth: false,
    myntRate: null,
    myntRateAuth: false,
    wbnbToBnbRate: null,
    wbnbToBnbRateAuth: false,
    myntToBnbRate: null,
    myntToBnbRateAuth: false,
    bnbToWbnbRate: null,
    bnbToWbnbAuth: false
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RATES:
            const {
                BNBtoUSD,
                WBNBtoUSD,
                MYNTtoUSD,
                WBNBtoBNB,
                MYNTtoBNB,
                BNBtoWBNB
            } = action.payload
            return {
                ...state,

                rate: BNBtoUSD,
                rateAuth: true,

                wbnbRate: WBNBtoUSD,
                wbnbRateAuth: true,

                myntRate: MYNTtoUSD,
                myntRateAuth: true,

                wbnbToBnbRate: WBNBtoBNB,
                wbnbToBnbRateAuth: true,

                myntToBnbRate: MYNTtoBNB,
                myntToBnbRateAuth: true,

                bnbToWbnbRate: BNBtoWBNB,
                bnbToWbnbAuth: true
            }
        case BEFORE_APP:
            return {
                ...state,
                rate: null,
                rateAuth: false,
                wbnbRate: null,
                wbnbRateAuth: false,
                myntRate: null,
                myntRateAuth: false,
                wbnbToBnbRate: null,
                wbnbToBnbRateAuth: false,
                myntToBnbRate: null,
                myntToBnbRateAuth: false,
                bnbToWbnbRate: null,
                bnbToWbnbAuth: false
            }
        default:
            return {
                ...state
            }
    }
}