import { ADD_FUNDRAISER_NFTS, BEFORE_MINT_NFTS, GET_FUNDRAISER_NFTS_STATS, BEFORE_FUNDRAISER_NFTS_STATS } from '../../redux/types'

const initialState = {
    addFundraiserNfts: null,
    addFundraiserNftsAuth: false,
    stats: null,
    statsAuth: false
}

export default function fundraisingRed(state = initialState, action) {
    switch (action.type) {
        case ADD_FUNDRAISER_NFTS:
            return {
                ...state,
                addFundraiserNfts: action.payload,
                addFundraiserNftsAuth: true
            }
        case GET_FUNDRAISER_NFTS_STATS:
            return {
                ...state,
                stats: action.payload,
                statsAuth: true
            }
        case BEFORE_FUNDRAISER_NFTS_STATS:
            return {
                ...state,
                stats: null,
                statsAuth: false
            }
        case BEFORE_MINT_NFTS:
            return {
                ...state,
                addFundraiserNfts: null,
                addFundraiserNftsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}