import { combineReducers } from 'redux'
import nftReducer from './../components/nfts/nfts.reducer'
import userReducer from './../components/user/user.reducer'
import walletReducer from './../components/wallet/wallet.reducer'
import errorReducer from './shared/error/error.reducer'
import settingsReducer from '../components/footer/footer.reducer'
import appReducer from '../App.reducer'
import nftHistoryReducer from '../components/history/history.reducer'
import nftReportingsReducer from '../components/nftReportings/nftReportings.reducer'
import favouritesReducer from '../components/my-favourites/favourites.reducer'
import gamificationReducer from '../components/gamification/gamification.reducer'
import fundraisingReducer from '../components/fundraising/fundraising.reducer'

export default combineReducers({
    nft: nftReducer,
    reports: nftReportingsReducer,
    user: userReducer,
    wallet: walletReducer,
    error: errorReducer,
    settings: settingsReducer,
    app: appReducer,
    nftHistory: nftHistoryReducer,
    favourites: favouritesReducer,
    gamification: gamificationReducer,
    fundraising: fundraisingReducer
})