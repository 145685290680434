import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { setPoints, createStats } from './gamification.action'
import { ENV } from '../../config/config'
const { getUserKeys } = ENV

const Gamification = (props) => {
    useEffect(() => {
        if (!props.gamification.points) {
            props.setPoints() // set points for gamification
        }
    }, [])

    useEffect(() => {
        if (props.gamification.points?.task?.length && props.eventName && props.eventName !== '') {
            if (props.userAddress)
                createGStats(props.eventName, props.userAddress)
            else
                createGStats(props.eventName)
        }
    }, [props.gamification.points, props.eventName])

    const createGStats = (eventT = '', userAddress = null) => {
        const nftPlatform = 'NFT marketplace'.toLowerCase()
        const userId = userAddress ? userAddress : getUserKeys('address')?.address // user wallet address
        const event = props?.gamification?.points.task.find((elem) => elem?.name === eventT && elem?.platform?.toLowerCase() === nftPlatform)
        if (!userId || !event)
            return false
        const { name, pointType, platform } = event
        const payload = {
            userId, eventName: name, pointType, platform
        }
        props.createStats(payload)
    }

    return (
        <>
        </>
    )
}

const mapStateToProps = (state) => ({
    gamification: state.gamification
})

export default connect(mapStateToProps, { setPoints, createStats }, null)(Gamification)