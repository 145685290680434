import React from 'react';

// layouts
import Layout1 from './layouts/layout1/layout1';
import Layout2 from './layouts/layout2/layout2';
import Layout4 from './layouts/layout4/layout4';

// components
const Wallet = React.lazy(() => import('./components/wallet/wallet'));
const Fundraising = React.lazy(() => import('./components/fundraising/fundraising-main/fundraising-main'))
const ItemDetailsCopy = React.lazy(() => import('./components/item-detail-copy/item-detail'));
const Profile = React.lazy(() => import('./components/profile/profile'));
const NotFound = React.lazy(() => import('./components/not-found/not-found'));

// routes
const routes = [
    { path: '/item/:item', access: true, exact: true, title: 'Fundraised NFT', layout: Layout2, component: ItemDetailsCopy },
    { path: '/login', access: true, exact: true, title: 'login', layout: Layout4, component: Wallet },
    { path: '/login/:referrer', access: true, exact: true, title: 'login', layout: Layout4, component: Wallet },
    { path: '/', access: true, exact: true, title: 'Template', layout: Layout2, component: Fundraising },
    { path: '/profile', access: true, exact: true, title: 'Profile', layout: Layout1, component: Profile },
    { path: '/*', access: true, exact: true, name: 'Not Found', layout: Layout2, component: NotFound }
];

export default routes;