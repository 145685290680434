import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTwitter, faGithub, faTelegram, faDiscord, faMedium, faInstagram, faRedditAlien } from '@fortawesome/free-brands-svg-icons'
import { Images } from '../../assets/asset';
import { ENV } from '../../config/config'
import { useSelector, useDispatch } from 'react-redux';
import { getSettings, beforeSettings } from './footer.action';
import { FiGithub } from 'react-icons/fi';
import { FaTelegramPlane } from 'react-icons/fa';

import './footer.css';
const { appName } = ENV

const MyntistFooter = () => {
    const dispatch = useDispatch()
    const [settings, setSettings] = useState(null);
    const settingsRes = useSelector((state) => state.settings)

    useEffect(() => {
        dispatch(getSettings())
    }, [])

    useEffect(() => {
        if (settingsRes.settingsAuth) {
            const { settings } = settingsRes
            dispatch(beforeSettings())
            setSettings(settings)
        }
    }, [settingsRes.settingsAuth])

    return (
        <>
            <footer id="myntist-footer" className="position-relative">
                <div className="top-footer">
                    <Container fluid>
                        <Row>
                            <Col md={6}>
                                <strong className="footer-logo mb-4 d-inline-block align-top">
                                    <Link className="d-inline-block align-top" to="/">
                                        <img src={Images.myntistLogo} alt="Site Logo" />
                                    </Link>
                                </strong>
                                <div className="about-hex text-white">
                                    <p>{settings && settings.desc ? settings.desc : ''}</p>
                                    {/* <p>Myntist is the future. Our complex dcommerce marketplace allows for crypto investments, the transfer of physical goods, non-fungible tokens (NFTs) and decentralized finance (DeFi) and we’re accessible globally!</p> */}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="footer-widget">
                                    <h3 className="">Join the Community</h3>
                                    {
                                        settings &&
                                        <ul className="list-unstyled social-links d-flex">
                                            {
                                                settings.facebook ?
                                                    <li className="facebook">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.facebook} target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faFacebookF} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.medium ?
                                                    <li className="medium">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.medium} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faMedium} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.linkedIn ?
                                                    <li className="linkedin">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.linkedIn} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.instagram ?
                                                    <li className="instagram">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.youtube ?
                                                    <li className="youtube">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.youtube} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.twitter ?
                                                    <li className="twitter">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.twitter} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.discord ?
                                                    <li className="discord">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.discord} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDiscord} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.reddit ?
                                                    <li className="reddit">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.reddit} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faRedditAlien} /></a>
                                                    </li> : null
                                            }
                                            {
                                                settings.github ?
                                                    <li className="github">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.github} target="_blank" rel="noreferrer">
                                                            {/* <FontAwesomeIcon icon={faGithub} /> */}
                                                            <FiGithub />
                                                        </a>
                                                    </li> : null
                                            }
                                            {
                                                settings.telegram ?
                                                    <li className="telegram">
                                                        <a className="d-flex justify-content-center align-items-center rounded-circle transition" href={settings.telegram} target="_blank" rel="noreferrer">
                                                            {/* <FontAwesomeIcon icon={faTelegram} /> */}
                                                            <FaTelegramPlane />
                                                        </a>
                                                    </li> : null
                                            }
                                        </ul>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="bottom-footer position-relative">
                    <Container>
                        <div className="copyright-text text-center text-white">
                            <p className="mb-0">All Rights Reserved by {appName} Company</p>
                        </div>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default MyntistFooter