import { BEFORE_USER, SET_USER, SET_INDIVIDUAL_USER, GET_USER, SET_BANNER } from '../../redux/types';

const initialState = {
    userData: null,
    userAuth: false,
    individualUserAuth: false,
    individualUser: null,
    bannerAuth: false,
    banner: null,
}

export default function userRed(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                userData: action.payload,
                userAuth: true
            }
        case GET_USER:
            return {
                ...state
            }
        case SET_BANNER:
            return {
                ...state,
                bannerAuth: true,
                banner: action.payload
            }
        case SET_INDIVIDUAL_USER:
            return {
                ...state,
                individualUser: action.payload,
                individualUserAuth: true
            }
        case BEFORE_USER:
            return {
                ...state,
                userData: null,
                userAuth: false,
                individualUserAuth: false,
                individualUser: null,
                bannerAuth: false,
                banner: null,
            }
        default:
            return {
                ...state
            }
    }
}