import { BEFORE_NFT, GET_NFT } from '../../redux/types';

const initialState = {
    nftsData: {},
    nftsAuth: false
}

export default function nftsRed(state = initialState, action) {
    switch (action.type) {
        case GET_NFT:
            return {
                ...state,
                nftsData: action.payload,
                nftsAuth: true
            }
        case BEFORE_NFT:
            return {
                ...state,
                nftsData: {},
                nftsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}