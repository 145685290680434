import { ENV } from '../config/config'
const { cdnBaseUrl } = ENV
const siteLogo = `${cdnBaseUrl}v1652166289/hex-nft/assets/logo_js4k0h.svg`;
const siteLogoM = `${cdnBaseUrl}v1655727474/hex-nft/assets/mynist_s1vmud.png`;
const iconSearch = `${cdnBaseUrl}v1652166671/hex-nft/assets/icon-search_b7dy65.svg`;
const bannerImg = `${cdnBaseUrl}v1655727483/hex-nft/assets/bg-banner-img_gpjreo.png`;
const gamingIcon = `${cdnBaseUrl}v1654003918/hex-nft/assets/gaming-logo_ef60f5.svg`;
const goldenBg  = `${cdnBaseUrl}v1654081602/hex-nft/assets/golden-bg-2_nixwcf.png`;   
const myntistLogo  = `${cdnBaseUrl}v1654510130/hex-nft/assets/myntist-logo_fnnuqb.png`;
const blueSearch  = `${cdnBaseUrl}v1654511165/hex-nft/assets/blue-search_jqi56l.png`;  
const cyanSearch  = `${cdnBaseUrl}v1657717903/hex-nft/assets/cyan-search_g1bcta.png`; 
const whiteSearch  = `${cdnBaseUrl}v1654511165/hex-nft/assets/white-search_jbj4tt.png`;
const uploadIcon  = `${cdnBaseUrl}v1654522861/hex-nft/assets/upload-icon_qvj33i.png`; 
const imageOne = `${cdnBaseUrl}v1654596276/hex-nft/assets/Group-52_ytb5dt.svg`;
const imageTwo = `${cdnBaseUrl}v1654596276/hex-nft/assets/Group-53_zfxkf5.svg`;
const imageThree = `${cdnBaseUrl}v1654596276/hex-nft/assets/Group-54_bailjh.svg`;
const imageFour = `${cdnBaseUrl}v1654596274/hex-nft/assets/Group-55_ceewkk.svg`;
const imageFive = `${cdnBaseUrl}v1654596274/hex-nft/assets/Group-56_vti2af.svg`;
const imageSix = `${cdnBaseUrl}v1654596274/hex-nft/assets/account_n84ugw.svg`;
const imageSeven = `${cdnBaseUrl}v1654596274/hex-nft/assets/wallet_x3div9.svg`;
const imageEight = `${cdnBaseUrl}v1654596274/hex-nft/assets/csv_cjlrmg.svg`;
const imageNine = `${cdnBaseUrl}v1654596274/hex-nft/assets/fill_bud4dp.svg`;
const imageTen = `${cdnBaseUrl}v1654596276/hex-nft/assets/upload_t5vg4v.svg`;
const ethIcon = `${cdnBaseUrl}v1657091124/hex-nft/assets/Icon-ethereum_paddbm.png`;
const iconCreateWallet = `${cdnBaseUrl}v1657094615/hex-nft/assets/wallet_fp7rnb.png`;
const iconAddNft = `${cdnBaseUrl}v1657094615/hex-nft/assets/add-nft_vz0qos.png`;
const iconCreateNft = `${cdnBaseUrl}v1657094615/hex-nft/assets/sell-nft_k3qqrn.png`;
const statusIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/status_hzeqac.png`; 
const statusIconNew = `${cdnBaseUrl}v1657119649/hex-nft/assets/status-new_f1ms9s.png`; 
const priceIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/price_cgmyi5.png`; 
const priceIconNew = `${cdnBaseUrl}v1657119650/hex-nft/assets/price-new_iplqqa.png`; 
const collectionIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/collection_ydfnbh.png`; 
const collectionIconNew = `${cdnBaseUrl}v1657119650/hex-nft/assets/collection-new_kwcbmf.png`; 
const categoryIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/category_c8legh.png`;  
const categoryIconNew = `${cdnBaseUrl}v1657119649/hex-nft/assets/category-new_zkoypd.png`; 
const authorIcon = `${cdnBaseUrl}v1657792609/hex-nft/assets/author_uvzrse.png`;  
const authorIconNew = `${cdnBaseUrl}v1657119651/hex-nft/assets/author-new_mv838r.png`; 
const favIcon = `${cdnBaseUrl}v1655727474/hex-nft/assets/mynist_s1vmud.png`; 
const eOne = `${cdnBaseUrl}v1657871529/hex-nft/assets/shopping-cart-svgrepo-com_incwlw.svg`;  
const eTwo = `${cdnBaseUrl}v1657871529/hex-nft/assets/online-shop-ecommerce-svgrepo-com_ehfib5.svg`; 
const eThree = `${cdnBaseUrl}v1657871529/hex-nft/assets/shopping-online-svgrepo-com_lodwvy.svg`; 
const allIcon = `${cdnBaseUrl}v1657188977/hex-nft/assets/fr-banner-bg_txi4ox.png`;

export const Images = {  
	siteLogo,
	iconSearch,
	bannerImg,
	iconCreateWallet,
	iconAddNft,
	iconCreateNft,
	gamingIcon,
	goldenBg,
	myntistLogo,
	blueSearch,
	whiteSearch,
	uploadIcon,
	imageOne,
	imageTwo,
	imageThree,
	imageFour,
	imageFive,
	imageSix,
	imageSeven,
	imageEight,
	imageNine,
	imageTen,
	ethIcon,
	statusIcon,
	statusIconNew,
	priceIcon,
	priceIconNew,
	collectionIcon,
	collectionIconNew,
	categoryIcon,
	categoryIconNew,
	authorIcon,
	authorIconNew,
	siteLogoM,
	cyanSearch,
	favIcon,
	eOne,
	eTwo,
	eThree,
	allIcon
}