import { BEFORE_GSTATS, SET_GPOINTS } from '../../redux/types'

const initialState = {
    points: null, // points daa retrieved from gamification
    statsAuth: false, // create stats for gamification auth,
}

export default function gamificationReducer(state = initialState, action) {
    switch (action.type) {
        case SET_GPOINTS:
            return {
                ...state,
                points: action.payload
            }
        case BEFORE_GSTATS:
            return {
                ...state,
                statsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}